// src/pages/Packages.tsx
import React from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import { FiCheck, FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'; // useNavigate'i ekleyin

const Packages = () => {
  const navigate = useNavigate();
  const { t } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('business success team', 'horizontal');

  const packages = [
    {
      title: t('packages.trial'),
      price: "Ücretsiz",
      meetings: "1",
      features: [
        t('packages.azureTranslation'),
        t('packages.teamsIntegration'),
        t('packages.instantTranscription'),
        t('packages.sslSecurity'),
        t('packages.meetingReports'),
        t('packages.techSupport')
      ],
      popular: false
    },
    {
      title: t('packages.basic'),
      price: "$49/ay",
      meetings: "10",
      features: [
        t('packages.azureTranslation'),
        t('packages.teamsIntegration'),
        t('packages.instantTranscription'),
        t('packages.sslSecurity'),
        t('packages.meetingReports'),
        t('packages.techSupport')
      ],
      popular: true
    },
    {
      title: t('packages.pro'),
      price: "$119/ay",
      meetings: "30",
      features: [
        t('packages.azureTranslation'),
        t('packages.teamsIntegration'),
        t('packages.instantTranscription'),
        t('packages.sslSecurity'),
        t('packages.meetingReports'),
        t('packages.techSupport')
      ],
      popular: false
    },
    {
      title: t('packages.enterprise'),
      price: "$",
      meetings: " - ",
      features: [
        t('packages.azureTranslation'),
        t('packages.teamsIntegration'),
        t('packages.instantTranscription'),
        t('packages.sslSecurity'),
        t('packages.meetingReports'),
        t('packages.techSupport')
      ],
      popular: false
    }
  ];

  const commonFeatures = [
    t('packages.azureTranslation'),
    t('packages.teamsIntegration'),
    t('packages.instantTranscription'),
    t('packages.sslSecurity'),
    t('packages.meetingReports'),
    t('packages.techSupport')
  ];

  return (
    <div className="container-fluid-custom">
      <div 
        className="packages-hero" 
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${heroImage})` : undefined,
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container className="text-center text-white py-5">
            <h1 className="display-4 mb-3">{t('packages.title')}</h1>
            <p className="lead">{t('packages.title1')}</p>
          </Container>
        )}
      </div>

      {/* Common Features Section */}
      <div className="content-section-alt text-center">
        <Container>
          <h2 className="mb-5">{t('packages.title2')}</h2>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="common-features">
                {commonFeatures.map((feature, index) => (
                  <div key={index} className="common-feature-item">
                    <FiCheck className="text-primary me-2" size={24} />
                    {feature}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Packages Section */}
      <div className="content-section">
        <Container>
          <Row className="g-4 justify-content-center">
            {packages.map((pkg, index) => (
              <Col key={index} md={6} lg={3}>
                <Card className={`h-100 shadow-lg border-0 package-card ${pkg.popular ? 'popular' : ''}`}>
                  {pkg.popular && (
                    <div className="popular-badge">{t('packages.populer')}</div>
                  )}
                  <Card.Header className="text-center border-0 pt-4 pb-0 bg-transparent">
                    <h3 className="package-title mb-0">{pkg.title}</h3>
                  </Card.Header>
                  <Card.Body className="text-center">
                    <div className="price-section mb-4">
                      <h2 className="display-4 fw-bold mb-0">{pkg.price}</h2>
                      <p className="text-muted">{pkg.meetings} Meeting/Monthly</p>
                    </div>
                    <ul className="feature-list list-unstyled">
                      {pkg.features.map((feature, idx) => (
                        <li key={idx} className="mb-3 d-flex align-items-center">
                          <FiCheck className="text-primary me-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                  <Card.Footer className="text-center border-0 pb-4 pt-0 bg-transparent">
                    <Button 
                      variant={pkg.popular ? "primary" : "outline-primary"} 
                      size="lg"
                      onClick={() => navigate('/contact')}
                      className="w-100 d-flex align-items-center justify-content-center"
                    >
                      {t('packages.select')}
                      <FiArrowRight className="ms-2" />
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      {/* FAQ Section */}
      <div className="content-section-alt">
        <Container>
          <h2 className="text-center mb-5">{t('packages.sss')}</h2>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="accordion" id="faqAccordion">
                {[
                  {
                    question: t('packages.sss1'),
                    answer: t('packages.sss11')
                  },
                  {
                    question: t('packages.sss2'),
                    answer: t('packages.sss22')                  },
                  {
                    question: t('packages.sss3'),
                    answer: t('packages.sss33')
                                    }
                ].map((faq, index) => (
                  <div key={index} className="accordion-item border-0 mb-3">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq${index}`}
                      >
                        {faq.question}
                      </button>
                    </h3>
                    <div
                      id={`faq${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Packages;
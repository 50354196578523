// src/components/Navigation.tsx
import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

import { 
  FiHome, 
  FiInfo, 
  FiPackage, 
  FiMail, 
  FiGlobe,
  FiMenu,
  FiFileText,
  FiShield
} from 'react-icons/fi';

const Navigation = () => {
  const { t, setLanguage } = useLanguage();

  


  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top" className="shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img 
            src="/logo.png"
            alt="CSC Voice AI"
            height="32"
            className="me-2"
          />
          <span className="brand-text">CSC Voice AI</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FiMenu size={24} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="nav-item">
              <FiHome className="nav-icon" />
              <span>{t('nav.home')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-item">
              <FiInfo className="nav-icon" />
              <span>{t('nav.about')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/packages" className="nav-item">
              <FiPackage className="nav-icon" />
              <span>{t('nav.packages')}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-item">
              <FiMail className="nav-icon" />
              <span>{t('nav.contact')}</span>
            </Nav.Link>
            
            {/* Legal Dropdown */}
            <NavDropdown 
              title={
                <div className="d-inline-flex align-items-center">
                  <FiFileText className="nav-icon" />
                  <span>{t('nav.legal')}</span>
                </div>
              }
              id="legal-nav-dropdown"
              className="nav-item"
            >
              <NavDropdown.Item as={Link} to="/terms" className="dropdown-item">
                <FiFileText className="nav-icon" />
                {t('nav.terms')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/privacy" className="dropdown-item">
                <FiShield className="nav-icon" />
                {t('nav.privacy')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          
          <Nav>
            <NavDropdown 
              title={
                <div className="d-inline-flex align-items-center">
                  <FiGlobe className="nav-icon" />
                  <span>{t('nav.language')}</span>
                </div>
              } 
              id="language-nav-dropdown"
              className="nav-item"
            >
              <NavDropdown.Item onClick={() => setLanguage('tr')}>Türkçe</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setLanguage('en')}>English</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { usePixabay } from '../hooks/usePixabay'; // Pixabay hook'unu import edin

const Popup = () => {
  const [show, setShow] = useState(true); // Pop-up başlangıçta açık
  const { imageUrl, loading } = usePixabay('Microsoft Teams App Store', 'horizontal'); // Microsoft Teams ile ilgili görsel arıyoruz

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>CSC Voice AI</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Görsel ve hizalama */}
        <div className="d-flex justify-content-center mb-3">
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <img 
              src={imageUrl || ''} 
              alt="Microsoft Teams App Store" 
              className="img-fluid rounded" // Görsel düzenlemesi için "rounded" ekledik
              style={{ maxHeight: '200px', objectFit: 'cover' }}
            />
          )}
        </div>

        {/* Mesaj içeriği */}
        <p className="text-center">
          Çok yakında Microsoft Teams App Store'da olacak olan ürünümüz <strong>CSC Voice AI</strong> ile tanışın!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Tamam
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;

const PIXABAY_API_KEY = process.env.REACT_APP_PIXABAY_API_KEY;
const PIXABAY_API_URL = 'https://pixabay.com/api/';

interface PixabayImage {
  id: number;
  pageURL: string;
  type: string;
  tags: string;
  previewURL: string;
  previewWidth: number;
  previewHeight: number;
  webformatURL: string;
  webformatWidth: number;
  webformatHeight: number;
  largeImageURL: string;
  imageWidth: number;
  imageHeight: number;
  imageSize: number;
  views: number;
  downloads: number;
  collections: number;
  likes: number;
  comments: number;
  user_id: number;
  user: string;
  userImageURL: string;
}

interface PixabayResponse {
  total: number;
  totalHits: number;
  hits: PixabayImage[];
}

export const searchPixabayImages = async (query: string, orientation: 'horizontal' | 'vertical' = 'horizontal'): Promise<PixabayImage | null> => {
  try {
    const response = await fetch(
      `${PIXABAY_API_URL}?key=${PIXABAY_API_KEY}&q=${encodeURIComponent(query)}&image_type=photo&orientation=${orientation}&per_page=3&safesearch=true`
    );
    const data: PixabayResponse = await response.json();

    if (data.hits.length > 0) {
      // Rastgele bir resim seç
      const randomIndex = Math.floor(Math.random() * Math.min(3, data.hits.length));
      return data.hits[randomIndex];
    }
    return null;
  } catch (error) {
    console.error('Error fetching image from Pixabay:', error);
    return null;
  }
};
import { useState, useEffect } from 'react';
import { searchPixabayImages } from '../services/pixabayService';

export const usePixabay = (query: string, orientation: 'horizontal' | 'vertical' = 'horizontal') => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [photographer, setPhotographer] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setLoading(true);
        const image = await searchPixabayImages(query, orientation);
        
        if (image) {
          setImageUrl(image.largeImageURL);
          setPhotographer(image.user);
        } else {
          setError('No image found');
        }
      } catch (err) {
        setError('Error loading image');
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [query, orientation]);

  return { imageUrl, photographer, loading, error };
};
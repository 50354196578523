// src/context/LanguageContext.tsx
import React, { createContext, useState, useContext } from 'react';


const trTranslations = {
  nav: {
    home: "Ana Sayfa",
    about: "Hakkımızda",
    packages: "Paketler",
    contact: "İletişim",
    language: "Dil",
    legal: "Yasal",
    terms: "Kullanım Koşulları",
    privacy: "Gizlilik Politikası"
  },
  terms: {
    title: "Kullanım Koşulları",
    effectiveDate: "Yürürlük Tarihi: 20 Kasım 2024",
    introduction: {
      title: "Giriş",
      content: "CSC Voice AI'a hoş geldiniz. Bu uygulama CSC Bilişim Tic. Ltd. Şti.'nin bir ürünüdür. Uygulamamızı kullanarak bu Kullanım Koşullarını kabul etmiş olursunuz. Kabul etmiyorsanız, lütfen kullanımı hemen durdurun."
    },
    license: {
      title: "Kullanım Lisansı",
      content: "Size CSC Voice AI'ı yalnızca amaçlanan kullanımı için sınırlı, münhasır olmayan ve devredilemez bir lisans veriyoruz: Microsoft Teams toplantılarında gerçek zamanlı çok dilli çeviriler sağlamak."
    },
    userResponsibilities: {
      title: "Kullanıcı Sorumlulukları",
      content: [
        "Toplantılarda ses kaydı ve çeviri için gerekli izinlere sahip olduğunuzdan emin olun.",
        "CSC Voice AI'ı yasa dışı veya yetkisiz amaçlar için kullanmayın.",
        "Microsoft Teams hesap bilgilerinizi güvende tutun.",
        "Kullanım şartlarına ve gizlilik politikasına uygun davranın.",
        "Diğer kullanıcıların haklarına saygı gösterin."
      ]
    },
    liability: {
      title: "Sorumluluk Sınırlaması",
      content: "CSC Bilişim Tic. Ltd. Şti., CSC Voice AI'ın kullanımından veya kullanılamamasından kaynaklanan hasarlardan sorumlu değildir. Uygulamayı kullanmanız kendi sorumluluğunuzdadır."
    },
    modifications: {
      title: "Değişiklikler",
      content: "Bu Kullanım Koşullarını herhangi bir zamanda değiştirme hakkını saklı tutarız. Değişiklikler yapıldıktan sonra CSC Voice AI'ı kullanmaya devam etmeniz, revize edilmiş koşulları kabul ettiğiniz anlamına gelir."
    },
    footer: "© 2024 CSC Bilişim Tic. Ltd. Şti. Tüm hakları saklıdır."
  },
  home: {
    title: "Toplantılarınızda Gerçek Zamanlı Ses Çevirisi",
    description: "CSC Bilişim Voice Meeting AI, Microsoft Teams toplantılarınızı Azure AI teknolojisi ile desteklenmiş çok dilli ses çevirisi ve deşifre özelliği ile dönüştürün. Türkçe, İngilizce, Rusça ve 24 dil desteği ile uluslararası toplantılarınızı daha verimli hale getirin.",
    getStarted: "Hemen Başlayın",
    features: {
      title: "Özelliklerimiz",
      translation: {
        title: "Gerçek Zamanlı Çeviri",
        description: "Kesintisiz iletişim için anlık ses çevirisi"
      },
      languages: {
        title: "Çoklu Dil Desteği",
        description: "Türkçe, İngilizce ve Rusça dahil 20+ dil desteği"
      },
      teams: {
        title: "Teams Entegrasyonu",
        description: "Microsoft Teams ile tam uyumlu çalışma"
      },
      hemenbasla: {
        title: "Hemen Başlayın",
        description: "CSC Voice AI ile toplantılarınızı daha verimli hale getirin.",
        hemenbuton: "Bizimle iletişime geçin"
      }
    }
  },
  about: {
    title: "Hakkımızda",
    subtitle: "AI destekli ses çevirisi ile global iletişimi dönüştürüyoruz",
    mission: "Misyonumuz",
    missionText: "İş dünyasında dil bariyerlerini ortadan kaldırıyoruz",
    missionDescription: "CSC Voice AI olarak, işletmelerin global iletişimini güçlendirmek için çalışıyoruz. Azure AI teknolojisi ile desteklenen çözümümüz, uluslararası toplantılarınızda anlık çeviri yaparak, ekiplerinizin daha etkili iletişim kurmasını sağlar. Misyonumuz, dil engellerini ortadan kaldırarak, işletmelerin global pazarda daha güçlü bir şekilde var olmalarına yardımcı olmaktır.",
    vizyon: "Vizyonumuz",
    vizyonDescription: "Global iletişimde lider teknoloji sağlayıcısı olmak ve dil bariyerlerini ortadan kaldırarak dünya çapında iş birliğini güçlendirmek.",
    statnumber1: "100+",
    statnumber2: "20+",
    statnumber3: "99%",
    activeUsers: "Aktif Müşteri",
    supportedLanguages: "Desteklenen Dil",
    customerSatisfaction: "Müşteri Memnuniyeti",
    deger: "Değerlerimiz",
    deger1:"Mükemmellik",
    deger2:"En yüksek kalitede hizmet sunmak için sürekli gelişim ve yenilik.",
    isbir1:"İş Birliği",
    isbir2:"Müşterilerimizle güçlü ortaklıklar kurarak ortak başarıya ulaşmak.",
    glog1:"Global Düşünce",
    glob2:"Kültürler arası iletişimi güçlendirerek global bir etki yaratmak.",
    team: {
      title: "Ekibimiz",
      ceo: {
        name: "Levent İLHAN",
        role: "Kurucu & CEO"
      },
      cto: {
        name: "YASEMİN SUGUR",
        role: "Teknoloji Direktörü"
      },
      head_ai: {
        name: "OSMAN YILMAZ",
        role: "AI ve Ar-Ge Direktörü"
      }
    }
  },
  contact: {
    title: "İletişim",
    title1: "Sizinle iletişime geçmek için hazırız",
    title2: "İletişim Formu",
    title3: "Lütfen İletişim Formunu doldurun",
    form: {
      name: "Ad Soyad",
      email: "E-posta",
      package: "Paket",
      selectPackage: "Paket seçiniz",
      message: "Mesaj",
      submit: "Gönder"
    }
  },
  packages: {
    title: "Paketlerimiz",
    title1: "Her ölçekteki işletme için uygun paketler",
    title2: "Tüm Paketlerde Sunulan Özellikler",
    populer: "En Polpüler",
    sss: "Sıkça Sorulan Sorular",
    sss1: "Deneme sürümü nasıl çalışır?",
    sss11: "Deneme sürümü ile bir toplantı için tüm özellikleri 24 saat boyunca ücretsiz kullanabilirsiniz.",
    sss2: "Paket yükseltmesi yapabilir miyim?",
    sss22: "Evet, dilediğiniz zaman paketinizi yükseltebilirsiniz. Kalan kullanım hakkınız yeni pakete aktarılır.",
    sss3: "Özel fiyatlandırma mümkün mü?",
    sss33: "Kurumsal müşterilerimiz için özel fiyatlandırma seçeneklerimiz mevcuttur. İletişime geçin.",
    trial: "Deneme",
    basic: "Başlangıç",
    pro: "Profesyonel",
    enterprise: "Özelleştirilmiş",
    select: "Paketi Seç",
    azureTranslation: "Azure AI Destekli Çeviri",
    teamsIntegration: "Teams Entegrasyonu",
    instantTranscription: "Anlık Transkripsiyon",
    sslSecurity: "SSL Güvenliği",
    meetingReports: "Tüm Dillerde Toplantı Raporu",
    techSupport: "Teknik Destek"
  },
  privacy: {
    title: "Gizlilik Politikası",
    subtitle: "Gizliliğinizi Korumak ve Verilerinizi Güvence Altına Almak",
    commitment: {
      title: "Gizlilik Taahhüdümüz",
      content: "CSC Voice AI'da gizliliğinizi ciddiye alıyoruz. Bu Gizlilik Politikası, gerçek zamanlı çeviri hizmetlerimizi kullanırken verilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu açıklar. Kullanıcılarımızın güvenini korurken yüksek kaliteli çeviri hizmetleri sunmaya kararlıyız."
    },
    dataCollection: {
      title: "Veri Toplama",
      content: "Ses verilerini yalnızca aktif çeviri oturumları sırasında topluyoruz. Tüm veriler gerçek zamanlı olarak işlenir ve kalıcı olarak depolanmaz."
    },
    dataSecurity: {
      title: "Veri Güvenliği",
      content: "Verileriniz kurumsal düzeyde güvenlik önlemleri ve şifreleme kullanılarak korunmaktadır. Konuşmalarınızın gizli kalmasını sağlamak için Azure'un güvenli altyapısını kullanıyoruz."
    },
    dataProtection: {
      title: "Veri Koruma",
      content: "Endüstri standardı güvenlik protokollerini uyguluyoruz ve verilerinizi yetkisiz erişime karşı korumak için güvenlik önlemlerimizi düzenli olarak güncelliyoruz."
    },
    contact: {
      title: "Bize Ulaşın",
      content: "Gizlilik Politikamız veya verilerinizi nasıl işlediğimiz hakkında sorularınız varsa, lütfen Veri Koruma Görevlimiz ile iletişime geçmekten çekinmeyin:"
    },
    lastUpdated: "Son güncelleme: 20 Kasım 2024"
  }
};

const enTranslations = {
  nav: {
    home: "Home",
    about: "About",
    packages: "Packages",
    contact: "Contact",
    language: "Language",
    legal: "Legal",
    terms: "Terms of Use",
    privacy: "Privacy Policy"
  },
  terms: {
    title: "Terms of Use",
    effectiveDate: "Effective Date: November 20, 2024",
    introduction: {
      title: "Introduction",
      content: "Welcome to CSC Voice AI, a product by CSC Bilişim Tic. Ltd. Şti. By using our application, you agree to abide by these Terms of Use. If you do not agree, please discontinue use immediately."
    },
    license: {
      title: "License to Use",
      content: "We grant you a limited, non-exclusive, and non-transferable license to use CSC Voice AI solely for its intended purpose: facilitating real-time multilingual translations in Microsoft Teams meetings."
    },
    userResponsibilities: {
      title: "User Responsibilities",
      content: [
        "Ensure that you have the necessary permissions for recording and translating audio during meetings.",
        "Do not use CSC Voice AI for any unlawful or unauthorized purpose.",
        "Keep your Microsoft Teams account credentials secure.",
        "Act in accordance with terms of use and privacy policy.",
        "Respect the rights of other users."
      ]
    },
    liability: {
      title: "Limitation of Liability",
      content: "CSC Bilişim Tic. Ltd. Şti. is not responsible for any damages resulting from the use or inability to use CSC Voice AI. Your use of the application is at your own risk."
    },
    modifications: {
      title: "Modifications",
      content: "We reserve the right to modify these Terms of Use at any time. Continued use of CSC Voice AI after changes are made constitutes acceptance of the revised terms."
    },
    footer: "© 2024 CSC Bilişim Tic. Ltd. Şti. All rights reserved."
  },
  home: {
    title: "Real-time Voice Translation for Your Meetings",
    description: "Transform your Teams meetings with CSC Voice AI, featuring real-time multilingual voice translation and transcription powered by Azure AI services. Support for Turkish, English, Russian, and 24 languages to enhance your international meetings.",
    getStarted: "Get Started",
    features: {
      title: "Our Features",
      translation: {
        title: "Real-time Translation",
        description: "Instant voice translation for seamless communication"
      },
      languages: {
        title: "Multiple Languages",
        description: "Support for 24+ languages including Turkish, English, and Russian"
      },
      teams: {
        title: "Teams Integration",
        description: "Seamlessly works with Microsoft Teams"
      },
      hemenbasla: {
        title: "Get Started Now",
        description: "Make your meetings more efficient with CSC Voice AI.",
        hemenbuton: "Contact Us"
      }

    }
  },
  about: {
    title: "About Us",
    subtitle: "Transforming global communication with AI-powered voice translation",
    mission: "Our Mission",
    missionText: "Breaking down language barriers in business communication",
    missionDescription: "At CSC Voice AI, we are dedicated to empowering businesses in their global communications. Our solution, powered by Azure AI technology, enables real-time translation in international meetings, allowing your teams to communicate more effectively. Our mission is to eliminate language barriers and help businesses thrive in the global marketplace.",
    vizyon: "Our Vision",
    vizyonDescription: "To be a leading technology provider in global communication and strengthen worldwide collaboration by eliminating language barriers.",
    statnumber1: "100+",
    statnumber2: "20+",
    statnumber3: "99%",
    activeUsers: "Active User",
    supportedLanguages: "Supported Language",
    customerSatisfaction: "Customer Satisfaction",
    deger: "Our Values",
    deger1:"Excellence",
    deger2:"Continuous improvement and innovation to provide the highest quality service.",
    isbir1:"Collaboration",
    isbir2:"Achieving mutual success by building strong partnerships with our customers.",
    glog1:"Global Thinking",
    glob2:"Creating a global impact by strengthening cross-cultural communication.",
    team: {
      title: "Our Team",
      ceo: {
        name: "Levent İLHAN",
        role: "Founder & CEO"
      },
      cto: {
        name: "LEVENT İLHAN",
        role: "Chief Technology Officer"
      },
      head_ai: {
        name: "LEVENT İLHAN",
        role: "AI & R&D Director"
      }
    }
  },
  contact: {
    title: "Contact Us",
    title1: "We are ready to connect with you.",
    title2: "Contact Form",
    title3: "Please fill out the contact form.",
    form: {
      name: "Full Name",
      email: "Email",
      package: "Package",
      selectPackage: "Select a package",
      message: "Message",
      submit: "Submit"
    }
  },
  packages: {
    title: "Our Packages",
    title1: "Packages suitable for businesses of all sizes",
    title2: "Features Offered in All Packages",
    populer: "Most Popular",
    sss: "Frequently Asked Questions",
    sss1: "How does the trial version work?",
    sss11: "With the trial version, you can use all features for one meeting free of charge for 24 hours.",
    sss2: "Can I upgrade my package?",
    sss22: "Yes, you can upgrade your package at any time. Your remaining usage rights will be transferred to the new package.",
    sss3: "Is custom pricing available?",
    sss33: "We offer custom pricing options for our corporate customers. Please get in touch.",
    trial: "Trial",
    basic: "Basic",
    pro: "Professional",
    enterprise: "Enterprise",
    select: "Select Package",
    azureTranslation: "Powered Translation",
    teamsIntegration: "Teams Integration",
    instantTranscription: "Instant Transcription",
    sslSecurity: "SSL Security",
    meetingReports: "Reports in All Languages",
    techSupport: "Technical Support"

  },
  privacy: {
    title: "Privacy Policy",
    subtitle: "Protecting Your Privacy and Securing Your Data",
    commitment: {
      title: "Our Commitment to Privacy",
      content: "At CSC Voice AI, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your data when you use our real-time translation services. We are committed to maintaining the trust and confidence of our users while delivering high-quality translation services."
    },
    dataCollection: {
      title: "Data Collection",
      content: "We collect and process voice data only during active translation sessions. All data is processed in real-time and is not stored permanently."
    },
    dataSecurity: {
      title: "Data Security",
      content: "Your data is protected using enterprise-grade security measures and encryption. We use Azure's secure infrastructure to ensure your conversations remain private."
    },
    dataProtection: {
      title: "Data Protection",
      content: "We implement industry-standard security protocols and regularly update our security measures to protect your data against unauthorized access."
    },
    contact: {
      title: "Contact Us",
      content: "If you have any questions about our Privacy Policy or how we handle your data, please don't hesitate to contact our Data Protection Officer:"
    },
    lastUpdated: "Last updated: November 20, 2024"
  }
};

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('tr');

  const t = (key: string): string => {
    const keys = key.split('.');
    let current: any = language === 'tr' ? trTranslations : enTranslations;
    
    for (const k of keys) {
      if (current === undefined) return key;
      current = current[k];
    }
    
    return current?.toString() || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
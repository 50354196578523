// src/pages/Privacy.tsx
import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import { FiShield, FiLock, FiUserCheck } from 'react-icons/fi';

const Privacy = () => {
  const { t } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('data security privacy', 'horizontal');

  return (
    <div className="container-fluid-custom">
      <div 
        className="privacy-hero py-5" 
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${heroImage})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container className="text-center text-white py-5">
            <h1 className="display-4">{t('privacy.title')}</h1>
            <p className="lead">{t('privacy.subtitle')}</p>
          </Container>
        )}
      </div>

      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="privacy-content">
              <section className="mb-5">
                <div className="section-icon mb-4">
                  <FiShield size={40} className="text-primary" />
                </div>
                <h2 className="text-primary mb-4">{t('privacy.commitment.title')}</h2>
                <p className="mb-4">
                  CSC Voice AI'da gizliliğinizi ciddiye alıyoruz. Bu Gizlilik Politikası, gerçek zamanlı çeviri 
                  hizmetlerimizi kullanırken verilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu 
                  açıklar. Kullanıcılarımızın güvenini korurken yüksek kaliteli çeviri hizmetleri sunmaya kararlıyız.
                </p>
              </section>

              <section className="mb-5">
                <div className="section-icon mb-4">
                  <FiLock size={40} className="text-primary" />
                </div>
                <h2 className="text-primary mb-4">{t('privacy.dataSecurity.title')}</h2>
                <p className="mb-4">
                  Verileriniz kurumsal düzeyde güvenlik önlemleri ve şifreleme kullanılarak korunmaktadır. 
                  Konuşmalarınızın gizli kalmasını sağlamak için Azure'un güvenli altyapısını kullanıyoruz.
                </p>
                <ul className="list-unstyled">
                  <li className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check-circle-fill text-primary me-2"></i>
                    End-to-end şifreleme
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check-circle-fill text-primary me-2"></i>
                    Düzenli güvenlik denetimleri
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check-circle-fill text-primary me-2"></i>
                    Azure güvenli altyapısı
                  </li>
                  <li className="mb-3 d-flex align-items-center">
                    <i className="bi bi-check-circle-fill text-primary me-2"></i>
                    Otomatik tehdit algılama
                  </li>
                </ul>
              </section>

              <section className="mb-5">
                <div className="section-icon mb-4">
                  <FiUserCheck size={40} className="text-primary" />
                </div>
                <h2 className="text-primary mb-4">{t('privacy.dataCollection.title')}</h2>
                <p className="mb-4">
                  Ses verilerini yalnızca aktif çeviri oturumları sırasında topluyoruz. Tüm veriler gerçek 
                  zamanlı olarak işlenir ve kalıcı olarak depolanmaz. Gizliliğiniz bizim için önemlidir.
                </p>
              </section>

              <section className="mb-5">
                <h2 className="text-primary mb-4">{t('privacy.contact.title')}</h2>
                <p className="mb-4">
                  Gizlilik Politikamız veya verilerinizi nasıl işlediğimiz hakkında sorularınız varsa, 
                  lütfen bizimle iletişime geçmekten çekinmeyin.
                </p>
                <p><a href="mailto:privacy@cscvoice.ai" className="text-primary">privacy@cscvoice.ai</a></p>
              </section>


            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Privacy;
// src/pages/Contact.tsx
import React from 'react';
import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import emailjs from '@emailjs/browser';

import { 
  FiMail, 
  FiPhone, 
  FiMapPin, 
  FiClock, 
  FiMessageCircle, 
  FiSend,
  FiUser
} from 'react-icons/fi';

const Contact = () => {
  const { t } = useLanguage();
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('customer service technology', 'horizontal');
  
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'İsim çok kısa')
      .max(50, 'İsim çok uzun')
      .required('Ad Soyad gereklidir'),
    email: Yup.string()
      .email('Geçerli bir email adresi giriniz')
      .required('Email adresi gereklidir'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Sadece rakam giriniz')
      .min(10, 'Telefon numarası en az 10 karakter olmalıdır')
      .max(11, 'Telefon numarası en fazla 11 karakter olmalıdır'),
    package: Yup.string()
      .required('Lütfen bir paket seçiniz'),
    message: Yup.string()
      .min(10, 'Mesaj çok kısa')
      .max(1000, 'Mesaj çok uzun')
      .required('Mesaj gereklidir')
  });


  interface FormValues {
    name: string;
    email: string;
    phone: string;
    package: string;
    message: string;
   }
   
   interface FormikHelpers {
    setSubmitting: (isSubmitting: boolean) => void;
    resetForm: () => void;
   }

   const handleSubmit = (values: FormValues, { setSubmitting, resetForm }: FormikHelpers) => {
    emailjs.send(
      'service_0s6s2e2',
      'template_6cxzh7n', 
      {
        to_name: "CSC Voice AI Team", // Template'de varsa ekleyin
        from_name: values.name,
        from_email: values.email,
        phone: values.phone,
        package: values.package,
        message: values.message
      },
      '29oOnAe-wAdWxAQsK'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Mesajınız başarıyla gönderildi!');
      setSubmitting(false);
      resetForm();
    })
    .catch((err) => {
      console.log('FAILED...', err);
      alert('Mesaj gönderilirken bir hata oluştu.');
      setSubmitting(false);
    });
  };

  const contactInfo = [
    {
      icon: <FiMail size={24} />,
      title: "Email",
      content: "info@cscvoice.ai",
      link: "mailto:info@cscvoice.ai"
    },
    {
      icon: <FiPhone size={24} />,
      title: "Telefon",
      content: "+90 (535) 234 59 09",
      link: "tel:+905352345909"
    },
    {
      icon: <FiMapPin size={24} />,
      title: "Adres",
      content: "Güvendik Mah. 241 Sk. No:20 İzmir, Türkiye",
      link: "https://maps.google.com"
    },
    {
      icon: <FiClock size={24} />,
      title: "Çalışma Saatleri",
      content: "Pazartesi - Cuma: 09:00 - 18:00",
      link: null
    }
  ];

  return (
    <div className="container-fluid-custom">
      {/* Hero Section */}
      <div 
        className="contact-hero" 
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${heroImage})` : undefined,
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container className="text-center text-white py-5">
            <h1 className="display-4 mb-3">{t('contact.title')}</h1>
            <p className="lead">{t('contact.title1')}</p>
          </Container>
        )}
      </div>

      {/* Contact Form Section */}
      <div className="contact-form-container py-5">
        <Container>
          <Row className="g-4 justify-content-center">
            {/* Contact Form */}
            <Col lg={8} className="order-lg-1">
              <Card className="shadow-lg border-0">
                <Card.Body className="p-5">
                  <div className="mb-4">
                    <h2>{t('contact.title2')}</h2>
                    <p className="text-muted">{t('contact.title3')}</p>
                  </div>
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      phone: '',
                      package: '',
                      message: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Ad Soyad</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text bg-light">
                                  <FiUser />
                                </span>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  className="form-control-lg bg-light"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  isInvalid={touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Email</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text bg-light">
                                  <FiMail />
                                </span>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  className="form-control-lg bg-light"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Telefon</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text bg-light">
                                  <FiPhone />
                                </span>
                                <Form.Control
                                  type="tel"
                                  name="phone"
                                  className="form-control-lg bg-light"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phone}
                                  isInvalid={touched.phone && !!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phone}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Paket Seçimi</Form.Label>
                              <div className="input-group">
                                <span className="input-group-text bg-light">
                                  <FiMessageCircle />
                                </span>
                                <Form.Select
                                  name="package"
                                  className="form-control-lg bg-light"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.package}
                                  isInvalid={touched.package && !!errors.package}
                                >
                                  <option value="">Paket seçiniz</option>
                                  <option value="trial">Deneme</option>
                                  <option value="basic">Başlangıç</option>
                                  <option value="pro">Profesyonel</option>
                                  <option value="enterprise">Kurumsal</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {errors.package}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Form.Group className="mb-4">
                          <Form.Label>Mesajınız</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="message"
                            className="form-control-lg bg-light"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            isInvalid={touched.message && !!errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.message}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                          className="w-100 btn-lg d-flex align-items-center justify-content-center"
                        >
                          {isSubmitting ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                              Gönderiliyor...
                            </>
                          ) : (
                            <>
                              <FiSend className="me-2" />
                              Mesaj Gönder
                            </>
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>

            {/* Contact Info */}
            <Col lg={4} className="order-lg-2">
              <div className="contact-info-wrapper">
                <Card className="shadow-lg border-0 bg-primary text-white h-100">
                  <Card.Body className="p-4">
                    <h3 className="mb-4">Bize Ulaşın</h3>
                    <div className="contact-info-list">
                      {contactInfo.map((info, index) => (
                        <div key={index} className="contact-info-item mb-4">
                          <div className="d-flex align-items-center">
                            <div className="contact-icon me-3">
                              {info.icon}
                            </div>
                            <div>
                              <h5 className="mb-1">{info.title}</h5>
                              {info.link ? (
                                <a 
                                  href={info.link} 
                                  className="text-white text-decoration-none"
                                  target={info.link.startsWith('http') ? '_blank' : undefined}
                                  rel={info.link.startsWith('http') ? 'noopener noreferrer' : undefined}
                                >
                                  {info.content}
                                </a>
                              ) : (
                                <p className="mb-0">{info.content}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
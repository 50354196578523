// src/pages/Home.tsx
import React from 'react';
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { useLanguage } from '../context/LanguageContext';
import { usePixabay } from '../hooks/usePixabay';
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'; // useNavigate'i ekleyin
import Popup from '../components/Popup'; // Popup bileşenini ekleyin

const Home = () => {
  const navigate = useNavigate();
  const { t, language } = useLanguage(); // Dil bilgisini aldık
  const { imageUrl: heroImage, loading: heroLoading } = usePixabay('business meeting technology Azure Open AI Microsoft Teams', 'horizontal');
  const { imageUrl: feature1Image, loading: feature1Loading } = usePixabay('voice recognition technology', 'horizontal');
  const { imageUrl: feature2Image, loading: feature2Loading } = usePixabay('microsoft teams meeting', 'horizontal');

  const features = [
    {
      title: t('home.features.translation.title'),
      description: t('home.features.translation.description'),
      query: 'technology communication'
    },
    {
      title: t('home.features.languages.title'),
      description: t('home.features.languages.description'),
      query: 'world languages'
    },
    {
      title: t('home.features.teams.title'),
      description: t('home.features.teams.description'),
      query: 'video conference'
    }
  ];

  const keyPoints = language === 'en' ? [
    'Real-time Speech Translation',
    '24+ Language Support',
    'High Accuracy Rate',
    'Easy Integration',
    'Secure and Private',
    'Instant Transcription'
  ] : [
    'Gerçek zamanlı ses çevirisi',
    '24+ dil desteği',
    'Yüksek doğruluk oranı',
    'Kolay entegrasyon',
    'Güvenli ve özel',
    'Anlık transkripsiyon'
  ];

  return (
    <div className="container-fluid-custom">
      <Popup />  {/* Popup bileşenini burada kullanıyoruz */}

      {/* Hero Section */}
      <div 
        className="hero-section"
        style={{
          backgroundImage: heroImage ? `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${heroImage})` : undefined,
        }}
      >
        {heroLoading ? (
          <Container className="text-center py-5">
            <Spinner animation="border" variant="light" />
          </Container>
        ) : (
          <Container>
            <Row className="align-items-center min-vh-75">
              <Col lg={8} className="text-white">
                <h1 className="display-4 fw-bold mb-4">{t('home.title')}</h1>
                <p className="lead fs-lg mb-4">{t('home.description')}</p>
                <Button 
                  variant="light" 
                  size="lg"
                  onClick={() => navigate('/packages')} 
                  className="d-inline-flex align-items-center"
                >
                  {t('home.getStarted')}
                  <FiArrowRight className="ms-2" />
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>

      {/* Features Section */}
      <div className="content-section">
        <Container>
          <h2 className="text-center display-5 mb-5">{t('home.features.title')}</h2>
          <Row className="g-4">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </Row>
        </Container>
      </div>

      {/* First Feature Highlight */}
      <div className="content-section-alt">
        <Row className="g-0">
          <Col md={6} className="order-md-2">
            <div className="image-section">
              {feature1Loading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={feature1Image || ''} 
                  alt="Real-time Translation"
                  className="w-100 h-100 object-fit-cover" 
                />
              )}
            </div>
          </Col>
          <Col md={6} className="order-md-1">
            <div className="text-content">
              <h2 className="h1 mb-4">{t('home.features.translation.title')}</h2>
              <p className="lead mb-4">{t('home.features.translation.description')}</p>
              <ul className="feature-list">
                {keyPoints.slice(0, 3).map((point, index) => (
                  <li key={index} className="mb-3 d-flex align-items-center">
                    <FiCheck className="text-primary me-2" size={24} />
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {/* Second Feature Highlight */}
      <div className="content-section">
        <Row className="g-0">
          <Col md={6}>
            <div className="image-section">
              {feature2Loading ? (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <Spinner animation="border" />
                </div>
              ) : (
                <img 
                  src={feature2Image || ''} 
                  alt="Teams Integration"
                  className="w-100 h-100 object-fit-cover" 
                />
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="text-content">
              <h2 className="h1 mb-4">{t('home.features.teams.title')}</h2>
              <p className="lead mb-4">{t('home.features.teams.description')}</p>
              <ul className="feature-list">
                {keyPoints.slice(3).map((point, index) => (
                  <li key={index} className="mb-3 d-flex align-items-center">
                    <FiCheck className="text-primary me-2" size={24} />
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {/* CTA Section */}
      <div className="content-section-alt text-center py-5">
        <Container>
          <h2 className="display-5 mb-4">{t('home.features.hemenbasla.title')}</h2>
          <p className="lead mb-4">{t('home.features.hemenbasla.description')}</p>
          <Button 
            variant="primary" 
            size="lg" 
            onClick={() => navigate('/packages')}
            className="d-inline-flex align-items-center"
          >
            {t('home.features.hemenbasla.hemenbuton')}
            <FiArrowRight className="ms-2" />
          </Button>
        </Container>
      </div>
    </div>
  );
};

const FeatureCard = ({ title, description, query }: {
  title: string;
  description: string;
  query: string;
}) => {
  const { imageUrl, loading } = usePixabay(query);

  return (
    <Col lg={4} className="mb-4">
      <Card className="h-100 shadow-sm hover-card border-0">
        {loading ? (
          <div className="text-center p-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <Card.Img 
            variant="top" 
            src={imageUrl || ''} 
            alt={title}
            className="feature-image"
            style={{ height: '200px', objectFit: 'cover' }}
          />
        )}
        <Card.Body>
          <Card.Title className="h4 mb-3">{title}</Card.Title>
          <Card.Text className="text-muted">{description}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Home;
